<template>
  <main class="page-markers page-new-marker">
    <PageTitleContainer class="pt-3">
      <PageTitle removeMargin>Nuovo segnaposto</PageTitle>

      <div class="text-right mb-3">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'modules.show' }">Indietro</router-link>
      </div>
    </PageTitleContainer>
    <div class="container">
      <PointForm :feedback.sync="feedback" @submitForm="submit" />
    </div>
  </main>
</template>

<script>

export default {
  name: 'point-new',
  components: {
    PageTitle: () => import('@/views/components/Layout/PageTitle'),
    PageTitleContainer: () => import('@/views/components/Layout/PageTitleContainer'),
    PointForm: () => import('@/views/components/Form/PointForm'),
  },
  props: {
    module: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      feedback: null,
    };
  },
  methods: {
    submit (pointForm) {
      const point = { ...pointForm };

      this.$api.createPoint(this.module.id, point)
        .then(res => {
          this.feedback = true;
          this.$router.push({ name: 'points.show', params: { pointId: res.data.id } });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
};

</script>

<style lang="scss" scoped>
.main-header {
  box-shadow: 0 0 15px rgba($body-color, 0.2);
}
</style>
